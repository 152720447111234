export function mapArray<T>(items: T[], key: keyof T): Partial<Record<string, T>> {
  return items.reduce<Partial<Record<keyof T, T>>>((agg, item) => {
    const itemKey = `${item[key]}`;

    return {
      ...agg,
      [itemKey]: item,
    };
  }, {});
}
