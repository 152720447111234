import { Feed } from "@/types/feed.types";
import { InboxStatusFilter } from "@/types/inbox.types";

export function getFeedStatusFilter(feed?: Feed): InboxStatusFilter {
  if (feed?.is_being_automated && !feed?.is_closed) return 'is_being_automated';

  if (feed?.is_closed) return 'is_closed';

  return 'open';
}
