import workspace from '@/plugins/axios/workspace'
import { Feed, FeedCounts, FeedItem, FeedWhatsAppConversations, HoolaMessage } from "@/types/feed.types";
import { ContactDtoFeed } from "@/types/contact.types";
import { FeedMetadataDto, InboxFeedsCounts, InboxStatusFilter, InboxTypeFilter } from "@/types/inbox.types";
import axiosApi from '@/plugins/axios/axiosApi'
import { WhatsAppObject } from '@/types/whatsapp.types';

interface FeedListResponse {
  contactFeeds: Partial<Record<string, string[]>>;
  contacts: ContactDtoFeed[];
  conversations: FeedWhatsAppConversations[];
  counts: FeedCounts[];
  feeds: Feed[]; 
  totalFeedCount: number;
}

interface FeedDetailsResponse {
  feed: Feed;
  items: FeedItem[];
  contactFeeds: any[];
  channels: {
    livechat: {
      status: 'idle' | 'active',
    },
    whatsapp: {
      lastConversation?: FeedWhatsAppConversations
      lastMessageDate?: string;
    }
  };
  messaging: {
    count: number,
  },
}

interface FeedMetadataResponse {
  feedMetadata: FeedMetadataDto;
}

interface FeedsCountsResponse {
  feedCounts: InboxFeedsCounts;
}

interface SendMessageResponse {
  message: HoolaMessage
}

export const feedsService = {
  async fetchFeedList(workspaceId: string, type: InboxTypeFilter, status: InboxStatusFilter, query: string, limit?: number): Promise<FeedListResponse> {
    const queryParam = query ? `&query=${query}` : '';
    const limitParam = limit ? `&limit=${limit}` : '';

    const response = await workspace.get<FeedListResponse>(`${workspaceId}/feeds?${type}=true&${status}=true&orderBy=last_interaction_date${queryParam}${limitParam}`);

    return response.data
  },

  async fetchFeedsCounts(workspaceId: string): Promise<FeedsCountsResponse> {
    const response = await workspace.get<FeedsCountsResponse>(`${workspaceId}/feeds/counts`);

    return response.data;
  },

  async fetchFeedDetails(workspaceId: string, feedId: string, limit: number): Promise<FeedDetailsResponse>  {
    const response = await workspace.get<FeedDetailsResponse>(`${workspaceId}/feeds/${feedId}?limit=${limit}`);

    return response.data;
  },

  async fetchFeedMetadata(workspaceId: string, feedId: string): Promise<FeedMetadataResponse> {
    const response = await workspace.get<FeedMetadataResponse>(`${workspaceId}/feeds/${feedId}/metadata`);

    return response.data;
  },

  async requestFeedAction(workspaceId: string, feeds: string[], type: 'Close' | 'Open') {
    await workspace.patch(`${workspaceId}/feeds/action`, { type, feeds });
  },

  async assignAgents(agentId: string, feedIds: string[]) {
    await axiosApi.post(`/agents/${agentId}/assign`, { feeds: feedIds });
  },

  async deleteNote(workspaceId: string, feedId: string, noteId: string) {
    await workspace.delete(`${workspaceId}/feeds/${feedId}/notes/${noteId}`);
  },

  async sendTextMessage(workspaceId: string, feedId: string, fromNumber: string, body: string, context?: { message_id: string, body: string }) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/send/feed/${feedId}?from=${fromNumber}`,
      { body, context },
    );

    return response;
  },

  async sendTemplateMessage(workspaceId: string, feedId: string, templateId: string, fromNumber?: string) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/whatsapp/send/feed/${feedId}/template/${templateId}?from=${fromNumber}`,
      {},
    )

    return response;
  },

  async sendReaction(workspaceId: string, feedId: string, externalMessageId: string, emoji: string, fromNumber: string) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/whatsapp/send/feed/${feedId}/reaction?from=${fromNumber}`,
      { message_id: externalMessageId, emoji },
    );

    return response;
  },

  async sendMedia(workspaceId: string, feedId: string, url: string, mimeType: string, caption: string, fromNumber: string) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/whatsapp/send/feed/${feedId}/media?from=${fromNumber}`,
      { media: { assetLocation: { url }, mimeType, caption } }
    );

    return response;
  },

  async sendNewTemplate(workspaceId: string, template: WhatsAppObject,  toNumber: string, fromNumber?: string) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/whatsapp/send/${toNumber}/template${fromNumber ? `?from=${fromNumber}` : ''}`,
      { template },
    );

    return  response;
  },

  async sendTemplateMessageToPhoneNumber(workspaceId: string, templateId: string, toNumber: string, fromNumber: string) {
    const response = await workspace.post<SendMessageResponse>(
      `${workspaceId}/whatsapp/send/${toNumber}/template/${templateId}?from=${fromNumber}`,
      {},
    );

    return response;
  },

  async uploadAsset(workspaceId: string, file: File): Promise<string | undefined> {
    const formData = new FormData()

    formData.append('file', file);

    const response = await workspace.post(
      `${workspaceId}/assets/conversations`,
      formData,
      { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } },
    );

    return response.data?.assetUrl;
  },
};
