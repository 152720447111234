import { ChatFeedItem, Feed, FeedItem, FeedWhatsAppConversations, HoolaMessage, MessageFeedItem } from "@/types/feed.types";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { Expect } from "@/utils/Expect";
import workspaceAxios from '@/plugins/axios/workspace'
import { FeedMetadataDto } from "@/types/inbox.types";
import { AgentDto, AgentTeamDto, useAgentStore } from "./useAgentStore";
import { isSamePhoneNumber } from '@/utils/isSamePhoneNumber';
import { createMessageGroups, shouldSquash } from "@/services/inbox/createMessageGroups";
import { createMessageGroupForFeedItem } from "@/services/inbox/inbox.helpers";
import { JSONContent } from "@tiptap/vue-3";
import { renderJSONContent } from "@/utils/renderJSONContent";
import { HoolaChannel } from "@/types/channel.types";
import { feedsService } from "@/services/feedsService";
import { IntegrationProvider } from "@/types/integration.types";
import { hoursBetweenNow } from "@/utils/hoursBetweenNow";
import { useInboxStore } from "@/stores/useInboxStore";
import { WhatsAppTemplateDto } from "@/types/whatsapp-template.types";

interface State {
  feed: Feed | undefined;
  feedLoadingError: string | undefined,
  feedItems: FeedItem[];
  isLoadingFeedItems: boolean;
  currentMessageOffset: number,
  totalFeedMessageCount: number,
  conversation: FeedWhatsAppConversations | undefined;
  feedMetadata: FeedMetadataDto | undefined;
  messageGroups: ChatFeedItem[];
  contactExternalIds: string[];
  feedChannels: {
    channel: HoolaChannel,
    id: string,
  }[];
  isLiveChatActive: boolean;
  selectedTemplatePreview: WhatsAppTemplateDto | undefined,
  temporaryTemplatePreview: WhatsAppTemplateDto | undefined,
  unreadCount: number;
  shouldCountUnread: boolean;
  isExternalChatProviderConfirmed: boolean;
}

export class MessageFilters {
  static DEFAULT_LIMIT = 25
}

export const useInboxCurrentFeedStore = defineStore('inboxCurrentFeed', {
  state: (): State => ({
    feed: undefined,
    feedLoadingError: undefined,
    feedItems: [],
    isLoadingFeedItems: false,
    currentMessageOffset: 0,
    totalFeedMessageCount: 0,
    conversation: undefined,
    feedMetadata: undefined,
    messageGroups: [],
    contactExternalIds: [],
    feedChannels: [],
    isLiveChatActive: false,
    selectedTemplatePreview: undefined,
    temporaryTemplatePreview: undefined,
    unreadCount: 0,
    shouldCountUnread: false,
    isExternalChatProviderConfirmed: false,
  }),
  getters: {
    templatePreview(state) {
      return state.temporaryTemplatePreview || state.selectedTemplatePreview;
    },
    mostRecentUserMessageDate(state): Date | undefined {
      let mostRecentCreatedAt: string | undefined;

      state.feedItems.forEach(item => {
        if (item.type !== 'MESSAGE') return;

        const isUserMessage = isSamePhoneNumber(`${item.data.sender.source}`, state.feed?.external_user_id);

        if (isUserMessage && (!mostRecentCreatedAt || item.data.created_at > mostRecentCreatedAt)) {
          mostRecentCreatedAt = item.data.created_at;
        }
      });

      if (!mostRecentCreatedAt) return;
    
      return new Date(mostRecentCreatedAt);
    },

    didUserReplyInConversation(state): boolean {
      if (!this.mostRecentUserMessageDate || !state.conversation?.expires_at) return false;

      const conversationStart = new Date(state.conversation.expires_at);

      conversationStart.setDate(conversationStart.getDate() - 1);

      return this.mostRecentUserMessageDate > conversationStart;
    },

    hoursToExpiry(state): number {
      let conversationHoursToExpiry = 0;

      if (this.didUserReplyInConversation && state.conversation) {
        conversationHoursToExpiry = hoursBetweenNow(state.conversation.expires_at); 
      }

      if (conversationHoursToExpiry > 0) {
        return conversationHoursToExpiry;
      }

      if (this.mostRecentUserMessageDate) {
        const expiresAt = new Date(this.mostRecentUserMessageDate);

        expiresAt.setDate(expiresAt.getDate() + 1);

        return hoursBetweenNow(expiresAt);
      }

      return 0;
    },

    isWhatsAppDirectMessageEnabled(): boolean {
      return this.hoursToExpiry > 0;
    },

    assignedAgent(state): AgentDto | undefined {
      const agentStore = useAgentStore();

      if (!state.feedMetadata?.assignedAgents.length) return;

      const [agent] = agentStore.findByIds(state.feedMetadata.assignedAgents);

      return agent;
    },

    assignedTeam(state): AgentTeamDto | undefined {
      const agentStore = useAgentStore();

      if (!state.feedMetadata?.assignedTeams.length) return;

      const [team] = agentStore.findTeamByIds(state.feedMetadata.assignedTeams);

      return team;
    },

    lastChannel(state): HoolaChannel | undefined {
      for (let i = state.feedItems.length - 1; i >= 0; i--) {
        const item = state.feedItems[i];

        if (item.type === 'MESSAGE') {
          return item.data.channel;
        }
      }
    },
  
    externalChatProvider(state): IntegrationProvider | undefined {
      if (state.isExternalChatProviderConfirmed) return undefined;

      return state.feed?.active_ticket_id ? state.feed.integration_ticket_provider : undefined;
    },

    hasMoreFeedItems(state): boolean {
      return state.totalFeedMessageCount > state.feedItems.length;
    },
  },
  actions: {
    async loadFeed(feedId: string, keepCurrentOffset = false) {
      const workspaceStore = useWorkspaceStore();
      const workspaceId = await workspaceStore.getWorkspaceId();

      if(!feedId) return;

      this.$patch({
        currentMessageOffset: keepCurrentOffset ? this.currentMessageOffset : MessageFilters.DEFAULT_LIMIT,
        feedLoadingError: undefined,
        isLoadingFeedItems: true,
      });

      const data = await feedsService.fetchFeedDetails(workspaceId, feedId, this.currentMessageOffset).catch((error) => {
        this.feedLoadingError = error.message;
      });

      if (keepCurrentOffset && data) {
        this.appendFeedItems(data.messaging.count, data.items.slice(0, data.items.length - this.feedItems.length));

        return;
      }

      this.feed = undefined;

      if (!data) return;

      const contactExternalIds: string[] = [];
      const feedChannels: State['feedChannels'] = [{
        id: data.feed.id,
        channel: data.feed.channel,
      }];

      Expect.array(data.contactFeeds).forEach((item) => {
        if (typeof item.external_user_id === 'string') {
          contactExternalIds.push(item.external_user_id);
        }

        feedChannels.push({
          channel: item.channel,
          id: item.id,
        });
      });

      const patch: Partial<State> = {
        feedItems: data.items,
        feed: {
          ...data.feed,
        },
        totalFeedMessageCount: data.messaging.count,
        messageGroups: createMessageGroups(data.items),
        contactExternalIds,
        feedChannels,
        isLiveChatActive: data.channels.livechat?.status === 'active',
        conversation: data.channels.whatsapp.lastConversation,
        isLoadingFeedItems: false,
        isExternalChatProviderConfirmed: false,
      }

      this.$patch(patch);
    },

    appendFeedItems(totalFeedMessageCount: number, feedItems: FeedItem[]) {
      this.$patch(state => {
        if (!state.feed) return;

        const messageGroups = createMessageGroups(feedItems);

        state.feedItems.push(...feedItems);
        state.messageGroups.push(...messageGroups);
        state.totalFeedMessageCount = totalFeedMessageCount;
        state.isLoadingFeedItems = false;
      });
    },

    async loadFeedMetadata(feedId: string) {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`feeds/${feedId}/metadata`)
      const { data } = await workspaceAxios.get(path, { withCredentials: true })
      const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

      this.feedMetadata = feedMetadata;
    },

    async loadMoreMessages() {
      if (!this.feed) throw Error('Could not load more messages. Feed not found');

      this.currentMessageOffset += MessageFilters.DEFAULT_LIMIT

      await this.loadFeed(this.feed.id, true)
    },

    async saveNoteToFeed(feedId: string, message: JSONContent, mentions: string[]): Promise<string | undefined> {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      const path = await workspaceStore.buildPath(`feeds/${feedId}/notes`);
      const response = await workspaceAxios.post(
          path,
          {
            message,
            mentions,
            html: renderJSONContent(message, true),
            attachments: [],
          }, 
          { withCredentials: true }
        ).catch(e => {
          snackbarStore.addError(
            'Saving the note failed',
            e.message
          );
        });

      if (this.feed) {
        await this.loadFeed(this.feed.id);
      }

      return Expect.string(response?.data?.id);
    },

    async deleteNote(feedId: string, noteId: string): Promise<boolean> {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      const workspaceId = await workspaceStore.getWorkspaceId();

      let isSuccess = true;

      await feedsService.deleteNote(workspaceId, feedId, noteId).catch((error) => {
        isSuccess = false;

        snackbarStore.handleError(error);
      });

      return isSuccess;
    },

    findMessageInCurrentFeedByExternalId(externalMessageId: string): HoolaMessage | undefined {
      const item = this.feedItems.find(item => {
        if (item.type !== 'MESSAGE') return;

        return item.data.external_message_id === externalMessageId
      });

      if (item?.type === 'MESSAGE') {
        return item.data;
      }
    },

    findExistingMessageFeedItem(searchFeedItem: FeedItem): MessageFeedItem | undefined {
      if (searchFeedItem.type !== 'MESSAGE') return;
      const message  = searchFeedItem.data;
      const item = this.feedItems.find(item => {
        if (item.type !== 'MESSAGE') return;

        return (message.external_message_id !== '' && item.data.external_message_id === message.external_message_id) || message.id === item.data.id;
      });

      if (item?.type === 'MESSAGE') {
        return item;
      }
    },

    addReaction(messageId: string,  reaction: string) {      
      this.feedItems.forEach((item) => {
        if (item.type !== 'MESSAGE' || item.data.external_message_id !== messageId) return;

        item.data.reaction = reaction;
      });
    },

    addFeedItem(feedItem: FeedItem) {
      this.$patch((state) => {
        if (!this.feed) return;

        this.feedItems.push(feedItem);

        const [lastMessageGroup] = state.messageGroups;

        if (lastMessageGroup.type === 'MESSAGE' && feedItem.type === 'MESSAGE') {
          const isSameGroup = lastMessageGroup.senderId === feedItem.data.sender.source.toString() &&  lastMessageGroup.initiator === feedItem.data.initiator;

          if (isSameGroup) {
            lastMessageGroup.messages.push(feedItem.data);

            return;
          }
        }

        if (lastMessageGroup.type === 'CONTACT_EVENT' && feedItem.type === 'CONTACT_EVENT') {
          if (shouldSquash(lastMessageGroup, feedItem)) {
            lastMessageGroup.squashed.push(feedItem);

            return;
          }
        }

        const newMessageGroup = createMessageGroupForFeedItem(this.feed, feedItem, this.contactExternalIds);

        if (newMessageGroup) {
          state.messageGroups.unshift(newMessageGroup);

          return;
        }
      });
    },
    updateConversation(conversation: FeedWhatsAppConversations) {
      const inboxStore = useInboxStore();

      inboxStore.addConversation(conversation);

      this.conversation = conversation;
    },
    async handleMessageEvent(event: MessageEvent<string>): Promise<false> {
      let payload: FeedItem | undefined;

      try {
        payload = JSON.parse(event.data);
      } catch(e) {
        console.error(e);
      }

      if (!payload) return false;
  
      if(payload.type === 'MESSAGE' && payload.metadata?.conversation) {
        this.updateConversation(payload.metadata.conversation)
      }
  
      if (payload.type === 'MESSAGE' && payload.data.message_type === 'reaction') {
        this.addReaction(
          payload.data.content.reaction.message_id,
          payload.data.content.reaction.emoji,
        );
  
        return false;
      }
  
      const existingMessageFeedItem = this.findExistingMessageFeedItem(payload)
  
      if(existingMessageFeedItem) {
        const message = existingMessageFeedItem.data;
  
        this.feedItems.forEach((item, index, arr) => {
          if (item.type !== 'MESSAGE') return item;
  
          if(item.data.id === message.id && item.data.status !== message.status) {
            item.data.status = message.status
            item.data.created_at = message.created_at
            arr[index] = item
          }
        })
  
        return false;
      }

      if (this.shouldCountUnread) {
        this.unreadCount++;
      }
  
      this.addFeedItem({
        ...payload,
        date: new Date(payload.date),
      });
  
      return false
    }
  },
});
