<script lang="ts" setup>
import { ContactTagDto } from '@/types/contact.types';

interface Props {
  tags: ContactTagDto[];
  showCount?: number;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    showCount: 2
  },
);

const showTags = computed(() => {
  return props.tags.slice(0, props.showCount);
});

const extraTagsCount = computed(() => {
  return props.tags.length - props.showCount;
});
</script>

<template>
  <div
    v-if="showTags.length"
    class="overflow-hidden d-flex ga-1 align-center"
  >
    <VChip
      v-for="tag in showTags"
      :key="tag.id"
      :color="tag.color"
      variant="flat"
      class="tags-chip-row__chip"
    >
      {{ tag.label }}
    </VChip>
    <span
      v-if="extraTagsCount > 0"
      style="display: inline-block;"
    >
      <VChip
        color="#d3d3d3"
        variant="tonal"
      >
        +{{ extraTagsCount }}
      </VChip>
    </span>
  </div>
</template>

<style scoped>
.tags-chip-row__chip {
  padding-right: 10px; 
  height: 20px; 
  border: 1px solid rgba(0,0,0,0.08);
}
</style>
