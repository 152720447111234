<script lang="ts" setup>
import { sortFeeds } from '@/services/inbox/inbox.helpers';
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore';
import { useInboxStore } from '@/stores/useInboxStore';

const agentPreferencesStore = useAgentPreferencesStore();
const inboxStore =  useInboxStore();

const { pinnedFeeds } = storeToRefs(agentPreferencesStore);
const { statusFilter, typeFilter, feeds } = storeToRefs(inboxStore);

const router = useRouter();

const didFetch = ref(false);

const sortedFeedItems = computed(() => {
  return sortFeeds(feeds.value, pinnedFeeds.value, 'DESC');
});

onMounted(async () => {
  typeFilter.value = 'all_conversations';
  statusFilter.value = 'open';

  await agentPreferencesStore.fetchAgentPreferences();
  await inboxStore.fetchFeedList();

  didFetch.value = true;
});

function handleOpen(feedId: string) {
  router.push(`/inbox/${feedId}`);
}
</script>

<template>
  <div v-if="didFetch">
    <DashboardFeedListCount :open-conversations-count="feeds.length" />
    <div class="d-flex flex-column ga-2">
      <InboxFeedCard
        v-for="item of sortedFeedItems"
        :key="item.id"
        :item="item"
        :is-active="false"
        :is-selected="false"
        :has-selection="false"
        disable-selection
        @open-feed="handleOpen(item.id)"
      />
    </div>
  </div>  
</template>
