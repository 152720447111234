import { ContactEventType } from "@/types/contact-event.types";
import { AISummaryData, ChatFeedItem, ContactEventItem, FeedItem, FlowStartedData, MessageGroup, Reaction } from "@/types/feed.types";
import { isSameDay } from "@/utils/isSameDay";
import { uuid } from "@/utils/uuid";

export const shouldSquash = (item: ContactEventItem, previousItem: ContactEventItem): boolean => {
  if (!isSameDay(new Date(item.date), new Date(previousItem.date))) return false;

  if (item.data.event_type === ContactEventType.CONTACT_PROPERTIES && item.data.event_type === previousItem.data.event_type) {
    return true;
  }

  if (item.data.event_type === ContactEventType.TAGS && item.data.event_type === previousItem.data.event_type) {
      return item.data.event_subtype === previousItem.data.event_subtype
  }

  return false;
}

export const createMessageGroups = (feedItems: FeedItem[]): ChatFeedItem[] => {
  const items: ChatFeedItem[] = []

  if (!feedItems.length) {
    return items;
  }

  const reactionMap = new Map<string, Reaction>();

  feedItems.forEach((item) => {
    if (item.type === 'MESSAGE' && item.data.message_type === 'reaction') {
      const reactObj = item.data.content['reaction'] as Reaction

      if(reactObj) {
        reactObj.senderId = item.data.content['senderId']

        reactionMap.set(reactObj.message_id, reactObj);
      }
    }
  });

  let currentGroup: MessageGroup | undefined;

  feedItems.forEach((item) => {
    if (item.type !== 'MESSAGE' && currentGroup) {
      items.push(currentGroup);
      currentGroup = undefined;
    }

    if (item.type === 'FEED_EVENT' && item.data.event_type === 'NOTES') {
      items.push({
        id: item.data.id,
        type: 'NOTE',
        channel: item.data.channel === 'RICH_NOTES' ? 'RICH_NOTES' : 'NOTES',
        message: item.data.event_data.message,
        creatorId: item.data.event_data.creatorId,
        createdAt: item.date
      });

      return;
    }

    if (item.type === 'FEED_EVENT' && item.data.event_subtype === 'FLOW_STARTED') {
      items.push({
        id: item.data.id,
        type: 'FLOW_STARTED',
        data: item.data.event_data as FlowStartedData,
        createdAt: item.date
      });

      return;
    }

    if (item.type === 'FEED_EVENT' && item.data.event_subtype === 'AI_OPEN_FEED_SUMMARY') {
      items.push({
        id: item.data.id,
        type: 'AI_OPEN_FEED_SUMMARY',
        data: item.data.event_data as AISummaryData,
        createdAt: item.date
      });

      return;
    }

    if (item.type == 'CONTACT_EVENT') {
      const lastItem = items[items.length - 1];

      if (lastItem?.type === 'CONTACT_EVENT' && shouldSquash(item, lastItem)) {
        lastItem.squashed.push(item);

        return;
      }

      items.push({
        ...item,
        squashed: [],
      });

      return;
    }

    if (item.type !== 'MESSAGE') return; // TODO Implement other event here (flow)

    if (item.data.message_type === 'reaction') return; // Don't show reaction messages in feed;

    item.data.reaction = reactionMap.get(item.data.external_message_id)?.emoji

    const addToCurrentGroup = currentGroup && (currentGroup.senderId === item.data.sender.source?.toString() && currentGroup.initiator === item.data.initiator);

    if (addToCurrentGroup) {
      currentGroup?.messages.push(item.data);

      return;
    }

    if (currentGroup) {
      items.push(currentGroup);
    }

    const senderId = item.data.sender.source?.toString();

    currentGroup = {
      id: uuid(),
      type: 'MESSAGE',
      senderId,
      initiator: item.data.initiator,
      origin: item.data.initiator_origin,
      messages: [item.data],
      isIncoming: item.data.initiator_origin === 'RECIPIENT',
    };
  });

  if (currentGroup) {
    items.push(currentGroup);
  }

  return items.reverse();
}
