import { ChatFeedItem, Feed, FeedItem } from "@/types/feed.types";
import { FeedsFilters } from "@/types/inbox.types";
import { isSamePhoneNumber } from "@/utils/isSamePhoneNumber";
import { uuid } from "@/utils/uuid";
import { createMessageGroups } from "./createMessageGroups";

export const buildGetAllFeedsString = (feedsFilters: FeedsFilters): string => {
    let base = 'feeds?';

    base = base.concat(`${feedsFilters.assignedFilter}=${feedsFilters.assignedFilterValue || true}`)
    base = base.concat(`&${feedsFilters.metadataFilter}=${true}`)
    base = base.concat(`&orderBy=last_interaction_date&order=${feedsFilters.order}`)

    if(feedsFilters.query) {
      base = base.concat(`&query=${feedsFilters.query}`)
    }

    return base;
  };

export const sortFeeds = (feeds: Feed[], pinnedFeedIds: string[], order: 'ASC' | 'DESC') => {
  const feedsCopy = [...feeds];
  
  feedsCopy.sort((f1, f2) => {
    const f1Pinned = pinnedFeedIds.find(feedId => f1.id === feedId)
    const f2Pinned = pinnedFeedIds.find(feedId => f2.id === feedId)

    const bothPinned = f1Pinned !== undefined && f2Pinned !== undefined
    const neitherPinned = f1Pinned === undefined && f2Pinned === undefined

    const comparison = order === 'DESC' ? f1.last_interaction_date > f2.last_interaction_date : f1.last_interaction_date < f2.last_interaction_date

    return bothPinned || neitherPinned ? (comparison ? -1 : 1) : f1Pinned ? -1 : 1
  })

  return feedsCopy;
};

export const createMessageGroupForFeedItem = (feed: Feed, feedItem: FeedItem, contactExternalIds: string[]): ChatFeedItem | undefined => {
  if (feedItem.type === 'MESSAGE') {
    const senderId = feedItem.data.sender.source.toString();

    return {
      id: uuid(),
      type: 'MESSAGE',
      senderId: `${feedItem.data.sender.source}`,
      initiator: feedItem.data.initiator,
      origin: feedItem.data.initiator_origin,
      messages: [feedItem.data],
      isIncoming: isSamePhoneNumber(senderId, feed.external_user_id) || contactExternalIds.some(item => isSamePhoneNumber(senderId, item)),
    };
  }

  const [messageGroup] = createMessageGroups([feedItem]);

  return messageGroup;
};
