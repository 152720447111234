import { Feed, FeedActionEvent, FeedCounts, FeedWhatsAppConversations } from "@/types/feed.types";
import { InboxFeedsCounts, InboxStatusFilter, InboxTypeFilter } from "@/types/inbox.types";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { feedsService } from "@/services/feedsService";
import { ContactDtoFeed } from "@/types/contact.types";
import { mapArray } from "@/utils/mapArray";
import { useAgentStore } from "./useAgentStore";
import { JSONContent } from "@tiptap/vue-3";
import { getFeedStatusFilter } from "@/utils/getFeedStatusFilter";

interface InboxStoreState {
  feeds: Feed[];
  contacts: Partial<Record<string, ContactDtoFeed>>;
  counts: Partial<Record<string, FeedCounts>>;
  conversations: Partial<Record<string, FeedWhatsAppConversations>>;
  typeFilter: InboxTypeFilter | undefined;
  feedsOrder: 'ASC' | 'DESC';
  statusFilter: InboxStatusFilter;
  isFetchingFeedList: boolean;
  isFetchingMoreFeedList: boolean;
  searchQuery: string;
  feedsCounts: InboxFeedsCounts | undefined;
  selectedFeedIds: string[];
  isSendingToNumber: boolean;
  draftMessages: Record<string, JSONContent | undefined>;
  totalFeedCount: number,
}

const FEEDS_PAGE_SIZE = 50;

export const useInboxStore = defineStore('inbox', {
  state: (): InboxStoreState => ({
    feeds: [],
    contacts: {},
    counts: {},
    conversations: {},
    feedsOrder: 'DESC',
    typeFilter: undefined,
    statusFilter: 'open',
    isFetchingFeedList: false,
    isFetchingMoreFeedList: false,
    searchQuery: '',
    feedsCounts: undefined,
    selectedFeedIds: [],
    isSendingToNumber: false,
    draftMessages: {},
    totalFeedCount: 0,
  }),
  getters: {
    isAllFeedsLoaded(state): boolean {
      return state.feeds.length >= state.totalFeedCount;
    },
  },
  actions: {
    async fetchFeedList() {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();

      const workspaceId = await workspaceStore.getWorkspaceId();

      if (!this.typeFilter || !this.statusFilter) return;

      this.isFetchingFeedList = true;

      const response = await feedsService.fetchFeedList(workspaceId, this.typeFilter, this.statusFilter, this.searchQuery).catch(snackbarStore.handleError);

      const contacts = mapArray(response?.contacts || [], 'id');
      const counts = mapArray(response?.counts ?? [], 'feed_id');
      const conversations = mapArray(response?.conversations || [], 'feed_id');

      this.$patch({
        feeds: response?.feeds ?? [],
        contacts,
        counts,
        conversations,
        selectedFeedIds: [],
        isFetchingFeedList: false,
        totalFeedCount: response?.totalFeedCount ?? 0,
      });
    },
    async fetchMoreFeedList() {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();

      if (!this.typeFilter || !this.statusFilter) return;

      this.isFetchingMoreFeedList = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      const limit = this.feeds.length + FEEDS_PAGE_SIZE;
      const response = await feedsService.fetchFeedList(workspaceId, this.typeFilter, this.statusFilter, this.searchQuery, limit).catch(snackbarStore.handleError);

      const missingFeedItems = (response?.feeds ?? []).slice(this.feeds.length);
      const contacts = mapArray(response?.contacts || [], 'id');
      const counts = mapArray(response?.counts ?? [], 'feed_id');
      const conversations = mapArray(response?.conversations || [], 'feed_id');

      this.$patch(state => {
        state.feeds.push(...missingFeedItems);

        state.contacts = contacts;
        state.counts = counts;
        state.conversations = conversations;
      });

      // wait for a render cycle to finish before enabling the scroll handler
      setTimeout(() => {
        this.isFetchingMoreFeedList = false;
      }, 0);
    },
    async refetchContacts() {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();

      const workspaceId = await workspaceStore.getWorkspaceId();

      if (!this.typeFilter || !this.statusFilter) return;

      const response = await feedsService.fetchFeedList(workspaceId, this.typeFilter, this.statusFilter, this.searchQuery).catch(snackbarStore.handleError);

      const contacts = mapArray(response?.contacts || [], 'id');

      this.$patch({ contacts });
    },
    async fetchFeedsCounts() {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();
      const workspaceId = await workspaceStore.getWorkspaceId();

      const response = await feedsService.fetchFeedsCounts(workspaceId).catch(snackbarStore.handleError);

      if (!response) return;

      this.$patch({
        feedsCounts: response.feedCounts,
      });
    },
    async requestActionOnFeed(feedId: string, action: 'Open' | 'Close'): Promise<boolean> {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();
      const workspaceId = await workspaceStore.getWorkspaceId();

      let isSuccess = true;

      await feedsService.requestFeedAction(workspaceId, [feedId], action).catch((e) => {
        isSuccess = false;

        snackbarStore.handleError(e)
      });

      if (!isSuccess) return false;

      const actionStatusMap: Record<typeof action, InboxStatusFilter[]> = {
        Open: ['is_closed'],
        Close: ['is_being_automated', 'open'],
      };

      if (actionStatusMap[action].includes(this.statusFilter)) {
        this.feeds = this.feeds.filter(item => item.id !== feedId);
      } else {
        this.fetchFeedList();
      }

      return true;
    },
    async closeSelectedFeeds() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();
      const workspaceId = await workspaceStore.getWorkspaceId(); 

      let isSuccess = true;

      await feedsService.requestFeedAction(workspaceId, this.selectedFeedIds, 'Close').catch((e) => {
        snackbarStore.handleError(e);

        isSuccess = false;
      });

      if (!isSuccess) return;

      await this.fetchFeedList()
    },
    async assignAgentToSelectedFeeds(agentId: string) {
      const snackbarStore = useSnackbarStore();

      if (!this.selectedFeedIds.length) return;

      let isSuccess = true;

      await feedsService.assignAgents(agentId, this.selectedFeedIds).catch((e) => {
        snackbarStore.handleError(e);
        isSuccess = false;
      });

      if (!isSuccess) return;

      await this.fetchFeedList();
    },
    toggleSelectedFeedId(feedId: string) {
      if(this.selectedFeedIds.includes(feedId)) {
        this.selectedFeedIds = this.selectedFeedIds.filter(item => item !== feedId);
      } else {
        this.selectedFeedIds = [...this.selectedFeedIds, feedId];
      }
    },
    replaceFeed(feed: Feed): boolean {
      let shouldReplaceFeed = false;

      const feeds = this.feeds.map(item => {
        if (item.id !== feed.id) return item;
  
        shouldReplaceFeed = true;

        return {
          ...item,
          last_content: feed.last_content,
          last_message_date: feed.last_message_date,
          last_feed_event_id: feed.last_feed_event_id,
          last_interaction_date: feed.last_interaction_date,
          last_message_status: feed.last_message_status,
        };
      });

      if (shouldReplaceFeed) {
        this.feeds = feeds;
      }

      return shouldReplaceFeed;
    },
    shouldFeedBeInList(feed: Feed): boolean {
      const agentStore = useAgentStore();

      if (this.typeFilter === 'all_conversations') {
        return true;
      }

      if (this.typeFilter === 'assigned_to_me') {
        return !!agentStore.currentAgent?.assignedFeeds.includes(feed.id);
      }

      if (this.typeFilter === 'mentioned') {
        return !!agentStore.currentAgent?.mentionedFeeds.includes(feed.id);
      }

      if (this.typeFilter === 'unassigned') {
        return !agentStore.allAssignedFeedIds.includes(feed.id);
      }

      return false;
    },
    pushFeedUpdate(feed: Feed) {
      const didReplaceFeed = this.replaceFeed(feed);

      if (didReplaceFeed) return;

      const status = getFeedStatusFilter(feed);

      if (status !== this.statusFilter) return;

      if (this.shouldFeedBeInList(feed)) {
        this.feeds.push(feed);
      }
    },
    addFeedTemporaryState(event: FeedActionEvent) {
      let replaceFeeds = false;
 
      const feeds = this.feeds.map(item => {
        if(item.id !== event.id) return item;

        replaceFeeds = true;

        if(['AGENT_TYPING', 'AGENT_RECORDING_AUDIO'].includes(event.action.type)) {
          return {
            ...item,
            temporaryState: {
              id: event.action.data,
              event: event.action.type
            },
          };
        }

        return {
          ...item,
          temporaryState: undefined,
        };
      });

      if (replaceFeeds) {
        this.feeds = feeds;
      }
    },
    addConversation(conversation: FeedWhatsAppConversations) {
      this.conversations = {
        ...this.conversations,
        [conversation.feed_id]: conversation,
      };
    },
  },
});
